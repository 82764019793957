<template>
    <div>
        <CCard class="zw-page">
            <CCardBody>
                <zw-table ref="table"
                          tableName="news"
                          columnsPrefix="news.column."
                          :items="myProvider"
                          :fields="fields"
                          :filter="filter"
                          :selected="selected"
                          :selectedSource="selectedSource"
                          title="common.title.news"
                          :actions-list="getActions()"
                >
                    <template #cell(file)="row">
                        <a :href="$root.getDocumentUrl(row.item.file, 'news')"
                           v-if="is_admin"
                           @click.prevent="$root.openDocument(row.item.file, 'news')"
                        >{{ row.item.file }}</a>
                        <span v-else>{{ row.item.file }}</span>
                    </template>
                    <template #cell(description)="row">
                        <div v-html="row.item.description"></div>
                    </template>
                    <template #cell(actions)="row">
                        <b-dropdown class="mb-1"
                                    left
                                    variant="primary"
                                    size="sm"
                        >
                            <template #button-content>
                                {{ $t('common.label.actions') }}
                            </template>

                            <b-dropdown-item v-if="row.item._edit"
                                             @click="showNew(row.item.id)">
                                {{ $t('common.title.show') }}
                            </b-dropdown-item>
                            <b-dropdown-item v-if="row.item._edit && is_admin"
                                             @click="$root.$children[0].openModal('news-modal', {newsId:row.item.id}, refreshTable)">
                                <font-awesome-icon class="mr-2" icon="edit"/>
                                {{ $t('common.title.edit') }}
                            </b-dropdown-item>
                            <b-dropdown-item v-if="row.item._delete  && is_admin"
                                             @click="deleteNews(row.item.id)">
                                <font-awesome-icon class="mr-2" icon="trash"/>
                                {{ $t('common.title.delete') }}
                            </b-dropdown-item>
                        </b-dropdown>
                    </template>
                </zw-table>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import moment from 'moment'
import commonTable from '@/bundles/erika_common_bundle/mixins/common-table'

export default {
    name: 'News',
    mixins: [commonTable],
    data() {
        return {
            is_admin: false,
            saveFilters: true,
        }
    },
    methods: {
        ...mapGetters('News', ['getThenewsTable', 'getThenew']),
        ...mapGetters('CommonData', ['getCommonData']),
        myProvider(ctx) {
            return this.commonProviderFetch(ctx, 'News/fetchThenews', 'getThenewsTable',{},(tableData)=>{
                this.is_admin = tableData.is_admin
            });
        },
        ctxBaseParams() {
            return {
                'startDate': this.dateStart,
                'endDate': this.dateEnd,
            }
        },
        showNew(id) {
            let commonData = this.getCommonData();
            this.$store.dispatch('News/fetchShowThenew', {
                domain: commonData.domain,
                userId: commonData.user.id,
                id: id
            })
                .then(() => {
                    this.news = this.getThenew()
                    if (this.news.id) {
                        this.$root.$children[0].openModal('show-news-modal', {news: this.news}, this.refreshTable, {width: '800px'})
                    }
                })
                .finally(() => {
                    this.loading = false;
                })
        },
        deleteNews(id) {
            this.$removeConfirm('News/deleteThenew', {
                thenewId: id
            }, this.refreshTable)
        },
        deleteSelected() {
            this.$removeConfirm('News/deleteThenews', this.selected.map(row => row.id), this.refreshTable)
        },
        getActions() {
            let actions = [
                {
                    title: this.$t('common.button.createNews'),
                    icon: 'plus',
                    hide: !this.$root.hasAccess('news', 'create'),
                    click: () => {
                        this.$root.$children[0].openModal('news-modal', {}, this.refreshTable)
                    },
                },
                {
                    title: this.$t('common.button.delete'),
                    icon: 'trash',
                    hide: !this.$root.hasAccess('news', 'delete'),
                    click: () => {
                        this.deleteSelected();
                    },
                }
            ]
            return this.is_admin ? actions : []
        }
    }
}
</script>